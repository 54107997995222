$(document).foundation();

// This part is done (BEFORE COMPLETING THE LOADING OF THE HTML PAGE). Hence, it is OUTSIDE the ready() function. 
// jQuery UI Tooltip 
// $(document).tooltip();

$(document).ready(function() {
	//var fileName = location.href.split("/").slice(-1); 
	//var fileName = location.pathname.split("/").slice(-1); 
	
	// Adds the class '.active' to the script's reference in the navigation menu 
	// 1. Finds each 'li' element, which is inside a 'nav' element
	$('nav li').each(function() 
	{
// alert ($(this).find('a').attr('href') +  ' - ' +  location.href.split("/").slice(-1) ); 		

		// 2. finds the 'a' element, which is inside the above - having 
		// 	  a: the name of the current browsed file == the href parameter 
		// OR b: the name of the current browsed file is '' (empty) == the href parameter is (./)
		if ( $(this).find('a').attr('href') == location.href.split("/").slice(-1) || ( $(this).find('a').attr('href') == './' && location.href.split("/").slice(-1) == '' ) )
		{ 
			// 3.1 adds the class '.active' to the 'li' element, 
			$(this).addClass('active'); 
			
			//and 3.2 adds the class '.active' to the grand-parent 'li' element (would apply to drop-down menu items)
			$(this).parent().parent().addClass('active'); 
		}
	});
	
	// add the script's name to each of the menu items in the language drop down 
	$('nav li').each(function() 
	{
		var link_text = /\.\.\/..\//.exec($(this).find('a').attr('href')); // find href's in the form href='../ar/' - where ar can be any 2 characters 
		if ( link_text )
		{ 
			//alert ( link_text + ' - ' + location.href.split("/").slice(-1) ); 
			$(this).find('a').attr('href', link_text + location.href.split("/").slice(-1) ); 
		}
	});
}); // end of ready() 



// ////////////////////////// 

// Change Language's cookie 
function change_language(lang_code)
{
	//eraseCookie('Lang'); 
	createCookie('Lang', lang_code, 30*24*60*60*1000); // 30 days 
//alert ('cookie: ' + document.cookie );

/*	// alert ('change language: ' + Date()); 
	var d = new Date();
    d.setTime(d.getTime() + (24*60*60*1000 *30)); // 30 days 
    var expires = "expires="+d.toUTCString();
	
	//alert ('cookie: ' + document.cookie); 
	document.cookie="Lang=" + lang_code + "; " + expires + "; path=/" ; // 30 days 
	// document.cookie="Lang=" + lang_code + "; " + "expires: Thu, 01 Jul 2014 00:00:00 UTC" + "; path=/" ; // 30 days 
*/	
}

/* 
//For the Slick Carousel - My version
var   $lang = $('html').attr('lang') // 'ar'
  , $dir = $('html').attr('dir') ; 

$('.carousel-auto').slick({ 
    dots: true
    ,infinite: true
    //,speed: 500 // Slide/Fade animation speed
    ,slidesToShow: 2
    ,slidesToScroll: 1
    ,autoplay: true
    ,autoplaySpeed: 2000 // Autoplay Speed in milliseconds
  ,pauseOnHover: true 
  ,pauseOnDotsHover: true
  
  //,mobileFirst: true 
  //,respondTo: 'window' //  Can be 'window', 'slider' or 'min' (the smaller of the two)
  // ,responsive 
  
  ,arrows: true 
  
  ,variableWidth: true 
  //,adaptiveHeight: true 
  //,easing: 'linear'
  //,centerMode: true      
  //,lazyLoad: 'progressive' // 'ondemand'      
    //,fade: true
    //,slide: 'div'
    //,cssEase: 'linear'
    //, customPaging: // See source for use example.
    ,rtl: ($dir == 'rtl') ? true : false // For rtl pages, this attribute has to be explicitly set. 
});

	
// social media buttons 
// Display 5 social media buttons 
if (!jQuery.browser.mobile) // this is defined in detectmobilebrowser.js (above)
{ 
	$('#social-buttons').share({
		networks: ['facebook','twitter','linkedin','googleplus','email'], //'pinterest', 'whatsapp',
		orientation: 'vertical',
		urlToShare: 'http://syriarelief.org.au',
		//pageDesc = '', 
		affix: ($dir == 'rtl') ? 'left top' : 'right top' // 'right center'
	});
}
// Display 5 social media buttons + WhatsApp 
else 	
{
	$('#social-buttons').share({
		networks: ['facebook','whatsapp','twitter','linkedin','googleplus','email'], //'pinterest'
		orientation: 'vertical',
		urlToShare: 'http://syriarelief.org.au',
		//pageDesc = '', 
		affix: ($dir == 'rtl') ? 'left top' : 'right top' // 'right center'
	});
}

*/ 

///////////////////////////// 
// Create a cookie
// milliseconds & path are optional 
// Usage: createCookie('userName','Mr X Y',7); 
function createCookie(name,value,milliseconds,path) 
{	
    if (milliseconds) 
    {
		var date = new Date();
// alert ( "date:  " + date );     
		date.setTime(date.getTime()+(milliseconds) ); // days*24*60*60*1000 
        var expires = "; expires="+date.toGMTString();
    }
	else 
	{ 
		var expires = ""; // default if no value was provided when calling the function 
	} 

    if (path) 
    {
        var scope = "; path=" + path; 
    }
	else 
	{ 
        var scope = "; path=/"; 
	}
	
    document.cookie = name+"="+value+expires+scope;
// alert (name + " - " + value + " - " + expires + " - " + scope + " - " + date );     
}

///////////////////////////// 
// Get a cookie
// Usage: var x = readCookie('ppkcookie')
// if (x) {
//     [do something with x]
// }
function readCookie(name) {
    var nameEQ = name + "=";
    var cookie_array = document.cookie.split(';');
    for(var i=0; i < cookie_array.length; i++) {
        var c = cookie_array[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) 
			return c.substring(nameEQ.length,c.length);
    }
    return null;
}

//////////////// 
// Erases a cookie 
function eraseCookie(name) {
    createCookie(name,"",-1); // double-check that there is no need to send this in "days" - (-1*24*60*60*1000) 
}

//////////////// 
// executeFunctionByName, e.g. var fun_name = 'myFunc'; executeFunctionByName(func_name, window); 
function executeFunctionByName(functionName, context /*, args */) {
  var args = [].slice.call(arguments).splice(2);
  var namespaces = functionName.split(".");
  var func = namespaces.pop();
  for(var i = 0; i < namespaces.length; i++) {
    context = context[namespaces[i]];
  }
  return context[func].apply(this, args);
}
 
